






























































































































































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { addressService } from '@/services/Address.service'
import { InvestorBasicProfile } from '@/interfaces/investor'
import { VueMaskDirective } from 'v-mask'
import isEmpty from '@/utils/isEmpty'

import {
  CivilState,
  Gender,
  Citizenship
} from '@/interfaces/credentials'
import UserService from '@/services/User.service'

@Component({
  directives: {
    mask: VueMaskDirective
  }
})
export default class UpdateUserProfile extends Vue {
  Gender = Gender
  CivilState = CivilState
  Citizenship = Citizenship
  isEmpty = isEmpty

  private userService = new UserService();

  /** Address form state */
  addressForm: any = {
    loading: false,
    message: null
  }

  /** Profile form state */
  profileState: any = {
    loading: false
  }

  /** Investor profile */
  profile: InvestorBasicProfile | null = {} as InvestorBasicProfile

  form: {
    email: string;
  } = {
    email: ''
  }

  formState = {
    loading: false
  }

  formErrors: Record<string, string> = {}

  /**
   * Handler for CEP input change.
   * Calls addressService to get address by given CEP.
   */
  onCepChange(event) {
    const cep = event.target.value

    if (!cep) return

    this.addressForm.loading = true

    addressService
      .findAddressByCEP(cep)
      .then((result) => {
        if (result.erro) {
          this.addressForm.message = 'CEP não encontrado.'
        } else {
          if (!this.profile) return

          this.profile.endereco.rua = result.logradouro
          this.profile.endereco.bairro = result.bairro
          this.profile.endereco.cidade = result.localidade
          this.profile.endereco.estado = result.uf

          this.addressForm.message = null
        }
      })
      .catch(() => {
        this.addressForm.message = 'CEP não encontrado.'
      })
      .then(() => {
        this.addressForm.loading = false
      })
  }

  onSubmit() {
    if (!this.form.email) {
      alert('Email é um campo obrigatório')
      return
    }

    this.formState.loading = true

    this.userService
      .profile(this.form.email)
      .then((response) => {
        if (!isEmpty(response)) {
          this.profile = response
        } else {
          alert('Perfil não encontrado.')
        }

        this.formState.loading = false
      })
      .catch((request) => {
        console.log(request)
        alert('Perfil não encontrado.')
      })
  }

  /**
   * Raw values (whitout mask characaters) of telefone and cep;
   */
  getRawValue(value): string {
    const regex = /\.?-? ?\(?\)?/g

    return value.replace(regex, '')
  }

  onProfileSubmit() {
    this.profileState.loading = true

    const p: any = { ...this.profile }

    p.endereco.telefone = this.getRawValue(p.endereco.telefone)
    p.endereco.cep = this.getRawValue(p.endereco.cep)

    this.userService
      .updateUserProfile({ cpf: p.cpf, profile: p })
      .then((response) => {
        this.profile = response
        alert('Perfil atualizado com sucesso.')
      })
      .catch((request) => {
        const m = request.response.data.message
        alert(m)
      })
      .then(() => {
        this.profileState.loading = false
      })
  }
}

