



























































import Vue from 'vue'
import Component from 'vue-class-component'
import SupportService from '@/services/Support.service'

@Component({})
export default class AutenticacaoPage extends Vue {
  private supportService = new SupportService();

  form: {
    email: string;
  } = {
    email: ''
  }

  formState = {
    loading: false
  }

  resetForm() {
    return {
      email: ''
    }
  }

  onSubmit() {
    if (!this.form.email) {
      alert('Email é um campo obrigatório')
      return
    }

    this.formState.loading = true

    this.supportService.authenticate(this.form.email)
      .then(() => {
        alert('Usuário autenticado com sucesso')
        this.form = this.resetForm()
      })
      .catch((request) => {
        const message = request.response.data || 'Erro ao autenticar usuário.'
        alert(message)
      })
      .then(() => {
        this.formState.loading = false
      })
  }
}

