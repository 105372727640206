


































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import Clipboard from 'v-clipboard'
import SupportService from '@/services/Support.service'
import OfferService from '@/services/Offer.service'
import { Offer } from '@/interfaces/offer'

Vue.use(Clipboard)

enum InvoiceType {
  PENDING = 'PENDING',
  DUPLICATED = 'DUPLICATED'
}

@Component({})
export default class UTMLinksGenerator extends Vue {
  private supportService = new SupportService();
  private offerService = new OfferService();

  InvoiceType = InvoiceType

  offers: Offer[] = []

  form: {
    invoiceId: string;
    offer: Offer | null;
    type: InvoiceType;
  } = {
    invoiceId: '',
    offer: null,
    type: InvoiceType.PENDING
  }

  formState = {
    loading: false
  }

  async mounted() {
    this.offers = await this.offerService.all()
  }

  resetForm() {
    return {
      invoiceId: '',
      offer: null,
      type: InvoiceType.PENDING
    }
  }

  onSubmit() {
    this.formState.loading = true

    const form = { ...this.form }

    if (InvoiceType[form.type] === InvoiceType.PENDING) {
      form.invoiceId = form.invoiceId.toUpperCase()

      this.supportService
        .cancelPendingInvoice(form.invoiceId)
        .then((response) => {
          alert('Fatura cancelada com sucesso.')
          this.form = this.resetForm()
          console.log(response)
        })
        .catch(request => {
          alert('Erro ao cancelar fatura. Verifique o console para mais informações.')
          console.log(request.response.data)
        })
        .then(() => {
          this.formState.loading = false
        })
    }

    if (InvoiceType[form.type] === InvoiceType.DUPLICATED && form.offer) {
      this.supportService
        .cancelDuplicatedInvoice(form.offer.url_oferta, form.invoiceId)
        .then(response => {
          alert('Fatura cancelada com sucesso.')
          console.log(response)
          this.form = this.resetForm()
        })
        .catch(request => {
          alert('Erro ao cancelar fatura. Verifique o console para mais informações.')
          console.log(request.response.data)
        })
        .then(() => {
          this.formState.loading = false
        })
    }
  }
}

