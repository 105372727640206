






























































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { IssueType, Navigator, OS } from '@/interfaces/support'
import { VueMaskDirective } from 'v-mask'
import SupportService from '@/services/Support.service'

@Component({
  directives: {
    mask: VueMaskDirective
  }
})
export default class NewIssue extends Vue {
  supportService = new SupportService();
  IssueType = IssueType
  Navigator = Navigator
  OS = OS

  form: any = {
    tipo_servico: null
  }

  formState = {
    loading: false
  }

  get issueTypesAsOptions() {
    return Object.keys(IssueType).map((type) => {
      return {
        value: type,
        label: IssueType[type]
      }
    })
  }

  get navigatorAsOptions() {
    return Object.keys(Navigator).map((type) => {
      return {
        value: type,
        label: Navigator[type]
      }
    })
  }

  get OSAsOptions() {
    return Object.keys(OS).map((type) => {
      return {
        value: type,
        label: OS[type]
      }
    })
  }

  get hasDescriptionField() {
    if (!this.form.tipo_servico) return false

    return [
      IssueType.ALTERACAO_DE_DADOS,
      IssueType.ALTERACAO_PERFIL_DE_INVESTIDOR,
      IssueType.BUG
    ].includes(this.form.tipo_servico.label)
  }

  get emptyForm() {
    return {
      tipo_servico: null
    } as any
  }

  onSubmit() {
    this.formState.loading = true

    const form: any = { ...this.form }

    form.tipo_servico = form.tipo_servico.value

    if (form.navegador) {
      form.navegador = form.navegador.value
    }

    if (form.sistema_operacional) {
      form.sistema_operacional = form.sistema_operacional.value
    }

    this.supportService
      .newIssue(form)
      .then(() => {
        alert('Issue cadastrada com sucesso.')
        this.form = this.emptyForm
        this.$emit('reload')
      })
      .catch(() => {
        alert('Erro ao cadastrar a issue.')
        this.form = this.emptyForm
      })
      .then(() => {
        this.formState.loading = false
      })
  }
}
