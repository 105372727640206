



















































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import NewIssue from '@/components/support/NewIssue.vue'
import SupportService from '@/services/Support.service'
import { groupBy } from 'underscore'
import { IssueStatus, IssueType, Navigator, OS } from '@/interfaces/support'

@Component({
  components: {
    NewIssue
  }
})
export default class BugReportingList extends Vue {
  private supportService = new SupportService();

  IssueStatus = IssueStatus
  IssueType = IssueType
  Navigator = Navigator
  OS = OS

  issues: any[] = []

  $moment

  formState = {
    loading: false
  }

  async mounted() {
    this.issues = await this.supportService.allIssues()
  }

  get issuesByStatus() {
    return groupBy(this.issues, 'status')
  }

  get aguardando() {
    return this.issues
      .filter((i) => {
        return IssueStatus[i.status] === IssueStatus.AGUARDANDO
      })
      .sort((a, b) => {
        const aDate = this.$moment(a.data_abertura, 'DD/MM/YYYY hh:mm:ss')
        const bDate = this.$moment(b.data_abertura, 'DD/MM/YYYY hh:mm:ss')

        return bDate.diff(aDate)
      })
  }

  get resolvendo() {
    return this.issues
      .filter((i) => {
        return IssueStatus[i.status] === IssueStatus.RESOLVENDO
      })
      .sort((a, b) => {
        const aDate = this.$moment(a.data_abertura, 'DD/MM/YYYY hh:mm:ss')
        const bDate = this.$moment(b.data_abertura, 'DD/MM/YYYY hh:mm:ss')

        return bDate.diff(aDate)
      })
  }

  get concluido() {
    return this.issues
      .filter((i) => {
        return IssueStatus[i.status] === IssueStatus.RESOLVIDO
      })
      .sort((a, b) => {
        const aDate = this.$moment(a.data_abertura, 'DD/MM/YYYY hh:mm:ss')
        const bDate = this.$moment(b.data_abertura, 'DD/MM/YYYY hh:mm:ss')

        return bDate.diff(aDate)
      })
  }

  fromNow(date, format = 'DD/MM/YYYY hh:mm:ss') {
    return this.$moment(date, format).fromNow()
  }

  async onReload() {
    this.formState.loading = true
    this.issues = await this.supportService.allIssues()
    this.formState.loading = false
  }

  setStatus(issue, status) {
    const confirm = window.confirm(`Tem certeza que deseja alterar o status do Ticket #${issue.id} de ${IssueStatus[issue.status]} para ${IssueStatus[status]}?`)

    if (!confirm) return

    this.formState.loading = true

    this.supportService.updateIssueStatus(issue.id, status)
      .then(async() => {
        this.issues = await this.supportService.allIssues()
      })
      .catch(() => {
        alert('Erro ao alterar status do Ticket.')
      })
      .then(() => {
        this.formState.loading = false
      })
  }
}

