
































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import JsonExcel from 'vue-json-excel'
import SupportService from '@/services/Support.service'
import OfferService from '@/services/Offer.service'
import IuguService from '@/services/Iugu.service'
import { Offer } from '@/interfaces/offer'
import isEmpty from '@/utils/isEmpty'

interface Query {
  urlOferta: string;
  offer: Offer | null;
  inicio: number;
  limite: number;
  ano: number;
}

@Component({
  components: { JsonExcel }
})
export default class UTMLinksGenerator extends Vue {
  private supportService = new SupportService();
  private offerService = new OfferService();
  private iuguService = new IuguService();

  // Fix ts error
  isEmpty = isEmpty

  TransactionToCSV = {
    Balanço: 'balanco',
    Cliente: 'cliente',
    'Data Entrada': 'data_entrada',
    Descrição: 'descricao',
    'ID Conta': 'id_conta',
    Quantidade: 'quantidade',
    Tipo: 'tipo',
    'Tipo de Referência': 'tipo_de_referencia',
    'Tipo de Transação': 'tipo_transacao'
  }

  offers: Offer[] = []

  form: Query = {
    urlOferta: '',
    offer: null,
    inicio: 0,
    limite: 1000,
    ano: 2021
  }

  formState = {
    loading: false,
    empty: false
  }

  state = {
    downloadLoading: false
  }

  transactions: any[] = []
  resume: any = {}

  async mounted() {
    this.offers = await this.offerService.all()
  }

  startDownload() {
    this.state.downloadLoading = true
  }

  finishDownload() {
    this.state.downloadLoading = false
  }

  fetchData() {
    return this.transactions
  }

  resetForm() {
    return {
      invoiceId: '',
      offer: null
    }
  }

  onSubmit() {
    this.formState.loading = true

    const form = { ...this.form }

    form.urlOferta = form.offer?.url_oferta as string

    this.iuguService
      .subAccountExtract(form)
      .then((response) => {
        const { transacoes, ...resume } = response
        this.transactions = transacoes
        this.resume = resume
      })
      .catch((request) => {
        console.log(request.response.data)
        alert('Erro ao consultar extrato.')
      })
      .then(() => { this.formState.loading = false })
      .then(() => { this.formState.empty = !this.transactions.length })
  }
}

