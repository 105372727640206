import axios, { AxiosInstance } from 'axios'

export default class OfferService {
  public INVESTMENT_ENDPOINT = 'https://investimento-dot-startmeup-218419.rj.r.appspot.com/investimento'
  public IUGU_ENDPOINT = 'https://iugu-dot-startmeup-218419.appspot.com/iugu'
  public BUG_REPORTING_ENDPOINT = 'https://bug-reporting-dot-smu-devs.appspot.com'
  public GATEWAY = 'https://api.startmeup.com.br/Gateway/v1'
  private client: AxiosInstance

  constructor() {
    this.client = axios
  }

  authenticate(email: string) {
    return this.client
      .get(`${this.GATEWAY}/auth/autentica/${email}`)
      .then((response) => response.data)
  }

  cancelPendingInvoice(invoiceId: string) {
    return this.client
      .delete(`${this.INVESTMENT_ENDPOINT}/cancela/pendente/${invoiceId}`)
      .then(response => response.data)
  }

  cancelDuplicatedInvoice(offerUrl: string, invoiceId: string) {
    return this.client
      .delete(`${this.IUGU_ENDPOINT}/fatura/cancelar/fatura/${offerUrl}/${invoiceId}`)
      .then(response => response.data)
  }

  newIssue(form) {
    return this.client
      .post(`${this.BUG_REPORTING_ENDPOINT}/tickets/salvar`, { ...form })
      .then((response) => response.data)
  }

  updateIssue(issueId, form) {
    return this.client
      .put(`${this.BUG_REPORTING_ENDPOINT}/tickets/atualizar/${issueId}`, { ...form })
      .then((response) => response.data)
  }

  updateIssueStatus(issueId, status) {
    return this.client
      .put(`${this.BUG_REPORTING_ENDPOINT}/tickets/atualizar/status/${issueId}`, {}, { params: { status } })
      .then((response) => response.data)
  }

  allIssues() {
    return this.client
      .get(`${this.BUG_REPORTING_ENDPOINT}/tickets/lista`)
      .then((response) => response.data)
  }
}
