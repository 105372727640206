
























































































































import Vue from 'vue'
import Component from 'vue-class-component'
import SupportService from '@/services/Support.service'
import OfferService from '@/services/Offer.service'
import IuguService from '@/services/Iugu.service'
import { Offer } from '@/interfaces/offer'

@Component({})
export default class ConsultarFaturasPage extends Vue {
  private supportService = new SupportService();
  private offerService = new OfferService();
  private iuguService = new IuguService();

  offers: Offer[] = []

  form: {
    invoiceId: string;
    offer: Offer | null;

  } = {
    invoiceId: '',
    offer: null
  }

  formState = {
    loading: false
  }

  invoice: any = {}

  async mounted() {
    this.offers = await this.offerService.all()
  }

  resetForm() {
    return {
      invoiceId: '',
      offer: null
    }
  }

  onSubmit() {
    this.formState.loading = true

    const form = { ...this.form }

    this.iuguService
      .findInvoice(form.offer?.url_oferta as string, form.invoiceId)
      .then(invoice => {
        this.invoice = invoice
        console.log(this.invoice)
      })
      .catch(request => {
        console.log(request.response)
        alert('Fatura não encontrada.')
      })
      .then(() => {
        this.formState.loading = false
      })
  }
}

