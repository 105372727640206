export enum IssueType {
  AUTENTICACAO='Autenticação',
  RESET_DE_SENHA='Reset de Senha',
  ALTERACAO_DE_DADOS='Alteração de Dados',
  ALTERACAO_PERFIL_DE_INVESTIDOR='Alteração Perfil de Investidor',
  BUG='Bug'
}

export enum IssueStatus {
  AGUARDANDO='Aguardando',
  RESOLVENDO='Em andamento',
  RESOLVIDO='Concluído',
  FALTANDO_INFORMACOES='Faltando Informações',
  CANCELADO='Cancelado'
}

export enum Navigator {
  SAFARI='Safari',
  MOZILLA='Mozilla',
  CHROME='Google Chrome',
  OPERA='Opera',
  EDGE='Edge',
  MAXTHON='Maxthon',
  BRAVE='Brave'
}

export enum OS {
  WINDOWS='Windows',
  MAC='Mac',
  LINUX='Linux',
  IOS='IOS',
  ANDROID='Android'
}
